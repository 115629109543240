
.dropdown-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.dropdown-menu.open {
    max-height: 200px; /* Adjust according to the height of your dropdown content */
}

.dropdown-menu li:hover{
    background-color: #bfc3cd;


}


