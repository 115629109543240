/* Add these styles to courses.css */
/* Target all scrollbars */
::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 5px; /* Height of the scrollbar (for horizontal scrollbar) */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #E9EBF1; /* White track color */
    border-radius: 0px 6px 6px 0px; /* Rounded corners on the handle */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Grey handle color */
    border-radius: 6px; /* Rounded corners on the handle */
  }