
/* Add these styles to courses.css */
/* Target all scrollbars */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 5px; /* Height of the scrollbar (for horizontal scrollbar) */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #ffffff; /* White track color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd; /* Grey handle color */
  border-radius: 4px; /* Rounded corners on the handle */
}
  
input{
    font-family: 'Manrope', 'sans-serif';
}
.sidebar-enter {
    transform: translateX(-100%);
}
.sidebar-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
}
.sidebar-exit {
    transform: translateX(0);
}
.sidebar-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms;
}



/* Dropdown transitions */
.dropdown-enter {
    opacity: 0;
    max-height: 0;
  }
  .dropdown-enter-active {
    opacity: 1;
    max-height: 500px; /* Adjust as needed */
    transition: opacity 500ms, max-height 500ms;
  }
  .dropdown-exit {
    opacity: 1;
    max-height: 500px;
  }
  .dropdown-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 500ms, max-height 500ms;
  }
  

.fee-slider {
    /* CSS for the wrapper element */
    margin: 30px 0px;
}

.fee-slider .range-slider__range {
    /* CSS for range */
    background: #273593 !important;
    height: 4px !important;
}

.fee-slider .range-slider__thumb {
    /* CSS for thumbs */
    background: #273593 !important;
    width: 14px !important;
    height: 14px !important;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
    cursor: pointer;
    accent-color: #273593 !important;
    width: 16px;
    height: 16px;
}
  
  .checkbox-container label {
    padding-left: 5px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;

  }
  