/* Header.css */
.header {
    background-color: #F3F3F3;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
  }

   a{
    text-decoration: none;
  }

  .phone-menu-toggle{
    display: none;
  }
  
  .header-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .logo img {
    width: 150px;
  }
  
  .main-menu ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .main-menu a {
    text-transform: uppercase;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #000;
  }
  
  .main-menu a:hover{
    color: #283593;

  }
  .book-button a {
    background-color: #283593;
    color: #fff;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 2px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.12);
  }

  .book-button a::after {
    content: '>';
    font-size: 24px;
    margin-left: 5px;
  
  }
  @media (max-width: 1024px) {

    .phone-menu-toggle{
      display: initial;
    }

    .main-menu.lg-display{
      display: none;
    }

    .header-container {
      align-items: center;
    }
  
    .main-menu.sm-display ul {
      flex-direction: column;
      padding: 0;
      align-items: center;
    }
  
    .main-menu.sm-display {
      text-align: center;
      text-decoration: none;
    }
  
    .book-button {
      display: none;
    }
  }
  