/* Footer.css */
.footer {
    background: url('https://globalgrads.org/wp-content/uploads/2023/10/Screenshot-2023-10-06-043837.jpg') center center/cover no-repeat;
    position: relative;
  }
  
  .footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  .footer-container {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 50px 20px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    padding: 0 20px;
  }
  
  .footer-column h4 {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .footer-column p {
    color: #fff;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8em;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-bottom {
    background-color: #FEB602;
    padding: 5px 20px;
    font-family: 'Heebo', sans-serif;
    color: #fff;
  }
  
  .footer-bottom a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      width: 100%;
      padding: 20px 0;
    }
  
    .footer-column h4, .footer-column p, .footer-column a {
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .footer-container {
      padding: 25px 10px;
    }
  
    .footer-column {
      padding: 10px 0;
    }
  
    .footer-column h4 {
      font-size: 14px;
    }
  
    .footer-column p, .footer-column a {
      font-size: 14px;
    }
  
    .footer-bottom {
      padding: 10px;
    }
  }
  